<template>
  <p style="padding: 1vw;">
    سیاست حفظ حریم خصوصی این خطمشی رازداری خط مشیها و رویههای ما را در مورد
    جمعآوری، استفاده و افشای اطالعات شما هنگام استفاده از سرویس توضیح میدهد و به
    شما درباره حقوق حریم خصوصی شما و نحوه محافظت قانون از شما میگوید. ما از داده
    های شخصی شما برای ارائه و بهبود خدمات استفاده می کنیم. با استفاده از این
    سرویس، شما با جمع آوری و استفاده از اطالعات مطابق با این سیاست حفظ حریم
    خصوصی موافقت می کنید. این خط مشی حفظ حریم خصوصی با کمک برنامه تولید سیاست
    حفظ حریم خصوصی ایجاد شده است. تعاریف برای اهداف این سیاست حفظ حریم خصوصی:
    حساب به معنای حساب منحصر به فردی است که برای دسترسی شما به سرویس ی ا بخشها
    یی از سرویس ما ایجاد شده است. برنامه بهbiochi ، برنامه نرم افزاری ارائه شده
    توسط شرکت اشاره دارد. شرکت )که در این قرارداد به عنوان "شرکت"، "ما " نامی ده
    می شود( به صحرا شرق کیمیا، اطالق م ی شود. کشور به: ایران اشاره دارد دستگاه
    به معنای هر دستگاهی است که می تواند به سرویس دسترسی داشته باشد مانند رایانه،
    تلفن همراه یا تبلت دیجیتال. داده های شخصی هر گونه اطالعات ی است که به یک فرد
    شناسا یی شده ی ا قابل شناسایی مربوط م ی شود. سرویس به برنامه اشاره دارد.
    ارائه دهنده خدمات به معنای هر شخص حقیقی یا حقوقی است که داده ها را از طرف
    شرکت پردازش می کند. این به شرکتهای شخص ثالث یا افرادی اطالق م یشود که توسط
    شرکت برای تسهی ل خدمات، ارائه خدمات از طرف شرکت، انجام خدمات مرتبط با سرویس
    یا کمک به شرکت در تجزیه و تحلیل نحوه استفاده از خدمات، استخدام شدهاند .
    دادههای استفاده به دادههایی اشاره دارد که بهطور خودکار جمع آوری میشوند، یا
    با استفاده از سروی س یا از زیرساخت سرویس )به عنوان مثال، مدت زمان بازدید از
    صفحه( ایجاد میشوند. منظور از شما شخصی است که به خدمات دسترسی یا استفاده می
    کند، یا شرکت ی ا اشخاص حقوقی د یگری که از طرف آن شخص به خدمات دسترسی ی ا
    استفاده م ی کند، در صورت لزوم. جمع آوری و استفاده از داده های شخصی شما انواع
    داده های جمع آوری شده اطالعات شخصی در حین استفاده از خدمات ما، ممکن است از
    شما بخواهیم اطالعات شناسایی شخصی خاصی را در اختیار ما قرار دهید که می تواند
    برای تماس یا شناسا یی شما استفاده شود. اطالعات قابل شناسا یی شخصی ممکن است
    شامل موارد زیر باشد، اما محدود به موارد زیر نیست: نام و نام خانوادگی شماره
    تلفن آدرس، ایالت، استان، کد پستی/پستی، شهر داده های استفاده داده های استفاده
    هنگام استفاده از سرویس، داده ها ی استفاده به طور خودکار جمع آوری می شود.
    داده های استفاده ممکن است شامل اطالعات ی مانند آدرس پروتکل اینترنت دستگاه
    شما) به عنوان مثال آدرس(IP ، نوع مرورگر، نسخه مرورگر، صفحات سرویس ما که
    بازدی د می کنید، زمان و تاریخ بازدی د شما، زمان صرف شده در آن صفحات، دستگاه
    منحصر به فرد باشد. شناسه ها و سا یر داده های تشخیصی هنگام ی که شما توسط یک
    دستگاه تلفن همراه یا از طریق آن به سرویس دسترسی پ یدا می کنید، ممکن است
    اطالعات خاصی را به صورت خودکار جمع آوری کنی م، از جمله، اما نه محدود به، نوع
    دستگاه تلفن همراهی که استفاده می کنید، شناسه منحصر به فرد دستگاه تلفن همراه
    شما، آدرس IP دستگاه همراه شما، تلفن همراه شما. سیستم عامل، نوع مرورگر
    اینترنت موبایلی که استفاده می کنید، شناسه های منحصر به فرد دستگاه و سایر
    داده های تشخیصی. ما همچنین ممکن است اطالعات ی را جمع آوری کنیم که مرورگر شما
    هر زمان که از سرویس ما بازدی د م ی کنید ی ا هنگامی که از طریق دستگاه تلفن
    همراه یا از طریق آن به سرویس دسترسی پ یدا م ی کنید، ارسال می کند . اطالعات
    جمع آوری شده در هنگام استفاده از برنامه هنگام استفاده از برنامه ما، به منظور
    ارائه ویژگی های برنامه ما، ممکن است با اجازه قبلی شما موارد زیر را جمع آوری
    کنیم: اطالعات مربوط به موقعیت مکان ی شما تصاویر و اطالعات د یگر از دوربین و
    کتابخانه عکس دستگاه شما ما از این اطالعات برای ارائه ویژگی های سروی س خود،
    برای بهبود و سفارشی سازی خدمات خود استفاده م ی کنیم. اطالعات ممکن است در
    سرورهای شرکت و/یا سرور ارائه دهنده خدمات آپلود شوند یا ممکن است به سادگ ی در
    دستگاه شما ذخیره شوند. میتوانید در هر زمان از طریق تنظیمات دستگاه خود،
    دسترسی به ای ن اطالعات را فعال ی ا غ یرفعال کن ید. استفاده از داده های شخصی
    شما شرکت ممکن است از داده های شخصی برای اهداف زیر استفاده کند: برای ارائه و
    حفظ سرویس ما، از جمله نظارت بر استفاده از سرویس ما. برای مدیریت حساب شما:
    برای مدیریت ثبت نام خود به عنوان کاربر سرویس. داده های شخصی که ارائه می کنید
    می تواند به شما امکان دسترسی به عملکردهای مختلف سرویس را بدهد که به عنوان یک
    کاربر ثبت شده در دسترس شما است. برای اجرا ی یک قرارداد: توسعه، انطباق و تعهد
    قرارداد خرید برای محصوالت، اقالم ی ا خدمات ی که خریداری کردهاید یا هر
    قرارداد دیگری با ما از طریق سرویس. برای تماس با شما: برای تماس با شما از
    طریق ایمیل، تماس های تلفنی، پ یامک، یا سایر اشکال مشابه از ارتباطات
    الکترونیکی، مانند برنامه تلفن همراه اعالنهای فشاری ication در مورد
    بهروزرسانیها ی ا ارتباطات اطالعات ی مربوط به عملکردها، محصوالت یا خدمات
    قراردادی، از جمله به روزرسانیهای امنیتی، در صورت لزوم یا منطقی برای اجرای
    آنها. برای ارائه اخبار، پ یشنهادات ویژه و اطالعات عمومی در مورد سایر کاالها،
    خدمات و روی دادها یی که ارائه می دهیم مشابه مواردی است که قبالً خریدار ی
    کرده اید یا درباره آنها پرس و جو کرده اید، مگر اینکه شما ترجیح داده باشید
    چنین اطالعاتی را دریافت نکنید. برای مدیریت درخواست های شما: برای حضور و
    مدیریت درخواست های شما به ما. برای نقل و انتقاالت تجاری: ما ممکن است از
    اطالعات شما برای ارزی ابی یا انجام ادغام، واگذاری، بازساز ی، سازمانده ی
    مجدد، انحالل، یا سا یر فروش یا انتقال برخی یا همه دارایی هایمان، چه به عنوان
    یک شرکت تداوم یافته یا به عنوان بخشی از ورشکستگی، انحالل، استفاده کنیم. یا
    اقدامات مشابه، که در آن داده های شخصی که توسط ما در مورد کاربران سرویس ما
    نگهداری می شود جزو دارایی های منتقل شده است. برای مقاصد دی گر: ممکن است از
    اطالعات شما برای مقاصد دیگر مانند تجزیه و تحلیل داده ها، شناسایی روند
    استفاده، تعیین اثربخشی کمپین های تبلیغاتی و ارزیابی و بهبود خدمات، محصوالت،
    خدمات، بازاریابی و تجربه شما استفاده کنیم. ما ممکن است اطالعات شخصی شما را
    در شرایط زیر به اشتراک بگذاریم: • با ارائه دهندگان خدمات: ما ممکن است
    اطالعات شخصی شما را با ارائه دهندگان خدمات به اشتراک بگذاریم تا استفاده از
    سرویس خود را نظارت و تجزیه و تحلیل کنیم تا با شما تماس بگیریم. • برای نقل و
    انتقاالت تجاری: ما ممکن است اطالعات شخصی شما را در ارتباط یا در خالل مذاکرات
    مربوط به ادغام، فروش دارایی های شرکت، تامین مال ی، یا خرید تمام یا بخشی از
    کسب و کارمان به شرکت دیگری به اشتراک بگذاریم ی ا انتقال دهیم. • با شرکت های
    وابسته: ممکن است اطالعات شما را با شرکت ها ی وابسته خود به اشتراک بگذاریم،
    در این صورت از آن شرکت های وابسته می خواهیم که این سیاست حفظ حریم خصوصی را
    رعایت کنند. شرکتهای وابسته شامل شرکت مادر و هر شرکت تابعه دی گر، شرکای سرما
    یهگذاری مشترک یا سایر شرکتهایی هستند که تحت کنترل ما هستند ی ا تحت کنترل
    مشترک با ما هستند. • با شرکای تجاری: ممکن است اطالعات شما را با شرکای تجاری
    خود به اشتراک بگذاریم تا محصوالت، خدمات ی ا تبلیغات خاصی را به شما ارائه
    دهیم. • با سایر کاربران: هنگام ی که شما اطالعات شخصی را به اشتراک می گذارید
    یا در مناطق عمومی با سایر کاربران تعامل می کنید، چنین اطالعاتی ممکن است توسط
    همه کاربران مشاهده شود و ممکن است به صورت عمومی در خارج از آن توزیع شود. •
    با رضایت شما: ممکن است با رضایت شما اطالعات شخصی شما را برای هر هدف دیگری
    فاش کنی م. حفظ اطالعات شخصی شما شرکت داده های شخصی شما را فقط تا زمان ی که
    برای اهداف تعیی ن شده در این سیاست حفظ حریم خصوصی ضروری باشد، حفظ خواهد کرد.
    ما اطالعات شخصی شما را تا حدی که برای پ یروی از تعهدات قانونی خود الزم است
    حفظ کرده و استفاده خواهیم کرد )به عنوان مثال، اگر از ما خواسته شود که داده
    های شما را برای مطابقت با قوانین قابل اجرا حفظ کنیم(، اختالفات را حل کرده و
    توافق نامه ها و خط مشی های قانونی خود را اجرا کنیم. شرکت همچنین دادههای
    استفاده را برای اهداف تحلیل داخلی حفظ خواهد کرد. دادههای استفاده عموماً برای
    مدت زمان کوتاهتری نگهداری میشوند، به جز زمان ی که این دادهها برای تقویت
    امنیت یا بهبود عملکرد سرویس ما استفاده میشوند، یا از نظر قانونی موظف به
    نگهداری این دادهها برا ی دورههای زمانی طوالنیتر هستیم. انتقال داده های شخصی
    شما اطالعات شما، از جمله داده های شخصی، در دفاتر عملیاتی شرکت و در هر مکان
    دیگری که طرف های درگیر در پردازش در آن قرار دارند پردازش می شود. این بدان
    معناست که این اطالعات ممکن است به رایانه هایی که در خارج از ایالت، استان،
    کشور یا سایر حوزه های قضایی دولتی شما واقع شدهاند، منتقل شوند - و در آنها
    نگهداری شود. رضایت شما با این سیاست حفظ حریم خصوصی و به دنبال آن ارسال چنین
    اطالعاتی نشان دهنده موافقت شما با آن انتقال است. شرکت تمام اقدامات منطقی
    الزم را انجام خواهد داد تا اطمینان حاصل شود که با داده های شما به طور ایمن و
    مطابق با این خط مشی رازداری رفتار می شود و هی چ انتقالی از داده های شخصی شما
    به سازمان یا کشوری صورت نمی گیرد مگر اینکه کنترل های کاف ی از جمله امنیت در
    محل وجود داشته باشد. داده ها و سایر اطالعات شخصی شما اطالعات شخصی خود را حذف
    کنید شما حق دارید اطالعات شخصی را که در مورد شما جمع آوری کرده ایم حذف کنید
    ی ا درخواست کنید که ما در حذف داده های شخصی کمک کنیم. سرویس ما ممکن است به
    شما ای ن امکان را بدهد که اطالعات خاصی را در مورد شما از داخل سرویس حذف
    کنید. شما م ی توانید در هر زمان با ورود به حساب کاربری خود، در صورت داشتن و
    بازدید از بخش تنظیمات حساب که به شما امکان می دهد اطالعات شخصی خود را مدیر
    یت کنید، اطالعات خود را به روز رسانی، اصالح یا حذف کنید . همچنین میتوانید
    برای درخواست دسترسی، تصحیح یا حذف اطالعات شخصی که به ما ارائه کردهاید، با ما
    تماس بگیرید. با این حال، لطفاً توجه داشته باش ید که زمان ی که یک تعهد قانونی
    ی ا مبنای قانونی برای انجام آن داریم، ممکن است الزم باشد اطالعات خاصی را حفظ
    کنیم. افشای اطالعات شخصی شما معامالت کسب و کار اگر شرکت درگیر ادغام، اکتساب
    ی ا فروش دارایی باشد، ممکن است اطالعات شخصی شما منتقل شود. قبل از اینکه داده
    های شخصی شما منتقل شود و مشمول یک س یاست حفظ حریم خصوصی متفاوت است اجرا ی
    قانون در شرایط خاصی، در صورتی که طبق قانون یا در پاسخ به درخواستهای معتبر
    مقامات دولتی )مانند دادگاه ی ا سازمان دولت ی( الزم باشد، ممکن است از شرکت
    خواسته شود اطالعات شخصی شما را افشا کند. سایر الزامات قانونی شرکت ممکن است
    اطالعات شخصی شما را با حسن نیت فاش کند که چنین اقدام ی برای موارد زیر ضروری
    است: • رعایت یک تعهد قانونی • حفاظت و دفاع از حقوق ی ا اموال شرکت • از
    اشتباهات احتمالی در ارتباط با سرویس جلوگیری یا تحقیق کنید • از ایمنی شخصی
    کاربران سرویس یا عموم محافظت کنید • در برابر مسئولیت قانونی محافظت کنید
    امنیت داده های شخصی شما امنیت داده های شخصی شما برای ما مهم است، اما به یاد
    داشته باشید که هیچ روش انتقال از طریق اینترنت یا روش ذخی ره الکترونیکی ٪100
    ایمن نیست. در حالی که ما تالش می کنیم از ابزارهای قابل قبول تجاری برای
    محافظت از داده های شخصی شما استفاده کنیم، نمی توانیم امن یت مطلق آن را تضمین
    کنیم. حریم خصوصی کودکان خدمات ما افراد زیر 13 سال را مورد خطاب قرار نمی دهد.
    ما آگاهانه اطالعات شناسایی شخصی را از افراد زیر 13 سال جمع آوری نمی کنیم.
    اگر شما والد ین یا قیم هستید و م ی دانید که فرزندتان اطالعات شخصی را به ما
    ارائه کرده است، لطفاً با ما تماس بگیرید. اگر متوجه شویم که داده های شخصی
    افراد زیر 13 سال را بدون تأ یید رضایت والدین جمع آوری کرده ایم، اقداماتی را
    برای حذف آن اطالعات از سرورهای خود انجام می دهیم . اگر الزم باشد به رضایت به
    عنوان مبنای قانونی برای پردازش اطالعات شما تکیه کنیم و کشور شما به رضایت یکی
    از والدین نیاز دارد، ممکن است قبل از جمعآوری و استفاده از آن اطالعات، به
    رضایت والدین شما نیاز داشته باشیم. پ یوند به سایر وب سایت ها خدمات ما ممکن
    است حاوی پ یوندها یی به وب سایت های دی گری باشد که توسط ما اداره نمی شوند.
    اگر روی پ یوند شخص ثالث کلیک کنید، به سایت آن شخص ثالث هدایت خواهید شد. ما
    قویاً به شما توصیه می کنیم که سیاست حفظ حریم خصوصی هر سایتی را که بازدید می
    کنید مرور کنید. ما هیچ کنترلی بر محتوا، سیاست های حفظ حریم خصوصی یا عملکرد
    سایتها ی ا خدمات شخص ثالث نداریم و مسئولیتی در قبال آن نداریم. تغییرات در
    این سیاست حفظ حری م خصوصی ممکن است سیاست حفظ حریم خصوصی خود را هر از چند
    گاهی به روز کنیم. ما با ارسال سیاست حفظ حریم خصوصی جدید در این صفحه شما را
    از هرگونه تغییر مطلع خواهیم کرد. قبل از اعمال تغییرات، از طریق ای میل و/یا
    اطالع یه برجسته در سرویس خود به شما اطالع میدهیم و تاریخ »آخرین بهروزرسانی«
    را در باالی این خطمشی رازداری بهروزرسانی میکنیم. به شما توصیه می شود برای هر
    گونه تغییر، این خط مشی رازداری را به صورت دوره ای مرور کنید. تغییرات در این
    سیاست حفظ حریم خصوصی زمانی که در این صفحه پست می شوند، موثر هستند. با ما
    تماس بگیرید اگر در مورد این سیاست حفظ حریم خصوصی سوالی دارید، می توانید با
    ما تماس بگیرید: از طریق ایمیل: com.gmail@agrobiochi با بازدید از این صفحه در
    وب سای ت ما: co.biochi
  </p>
</template>

<script>
export default {};
</script>

<style>
</style>